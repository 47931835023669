import React, { useRef, useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { navigate } from "gatsby";
import arrowRight from "../images/home/arrow_right.png";
import helicopter from "../images/solutions/helicopter.png";
import fileImg from "../images/solutions/fileImg.png";
import solutionImg1 from "../images/solutions/solution_features_img1.png";
import solutionImg2 from "../images/solutions/solution_features_img2.png";
import solutionImg3 from "../images/solutions/solution_features_img3.png";
import wonderfulImg1 from "../images/solutions/wonderful_cases_img1.png";
import wonderfulImg2 from "../images/solutions/wonderful_cases_img2.png";
import wonderfulCasesImg1 from "../images/solutions/partnersImg1.png";
import wonderfulCasesImg2 from "../images/solutions/partnersImg2.png";
import wonderfulCasesImg3 from "../images/solutions/partnersImg3.png";
import wonderfulCasesImg4 from "../images/solutions/partnersImg4.png";
import wonderfulCasesImg5 from "../images/solutions/partnersImg5.png";
import allSolutionsImg1 from "../images/solutions/all_solutions1.png";
import allSolutionsImg2 from "../images/solutions/all_solutions2.png";
import allSolutionsImg3 from "../images/solutions/all_solutions3.png";
import bannerImg from "../images/solutions/bannerImg.png";
import partnersImg5 from "../images/home/rcslogo.png";
import "../style/solutions.less";
import Fade from "react-reveal/Fade";
export default function Solutions() {
  // const painPointsList = [
  //     { id: 0, img: fileImg, title: "Lack of top-level design and planning", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" },
  //     { id: 1, img: fileImg, title: "Lack of strong technical support", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" },
  //     { id: 2, img: fileImg, title: "Most lack growth programs", text: "Provide growth methodology, combine the actual experience of serving customers, and customize solutions for each customer" }
  // ]
  const allSolutions = useRef(null);
  const allSolutionsList = [
    {
      id: 0,
      img: allSolutionsImg1,
      title: "Data privacy and security",
      text: "Address by using strong data protection & strict data governance.",
    },
    {
      id: 1,
      img: allSolutionsImg2,
      title: "Bias",
      text: "Mitigate by selecting data & regularly reviewing algorithm fairness.",
    },
    {
      id: 2,
      img: allSolutionsImg3,
      title: "Employee Unemployment",
      text: "Ease impact by communicating benefits & providing reskilling opportunities.",
    },
  ];
  const wonderfulCasesList = [
    {
      id: 0,
      img: wonderfulImg1,
      title: "Healthcare",
      text: "Create better outcomes in healthcare through data utilization and collaboration",
    },
    {
      id: 1,
      img: wonderfulImg2,
      title: "Smart Cities",
      text: "Empower cities with smart services while protecting the data and privacy of residents",
    },
    {
      id: 2,
      img: wonderfulImg2,
      title: "Financial Services",
      text: "Streamline and improve customer interactions, risk mitigation and fraud protection",
    },
  ];
  const wonderfulCasesImg = [
    // wonderfulCasesImg1,
    // wonderfulCasesImg2,
    // wonderfulCasesImg3,
    // wonderfulCasesImg4,
    partnersImg5,
  ];
  const [solutionsShadow, setSolutionsShadow] = useState(false);
  useEffect(() => {
    const observer =
      new IntersectionObserver(
        (entries) => {
          setSolutionsShadow(entries[0].isIntersecting);
        },
        {
          threshold: [1],
        }
      ) || {};
    observer.observe(allSolutions.current);
  });

  return (
    <>
      <div className="solutions">
        <Header />
        {/* Tailored security solutions for your industry */}
        <div className="tailored">
          <div className="tailored_content">
            <Fade distance={"20%"} top duration={1300}>
              <h3>Chief of Staff AI is a solution</h3>
            </Fade>
            <p>
              It can automate routine tasks, provide real-time data insights,
              improve efficiency and productivity, and provide a cost-effective
              alternative to hiring additional staff.
            </p>
            <div onClick={() => navigate("/contactUs")} className="more">
              More
              <img src={arrowRight} alt="" />
            </div>
          </div>
          <div className="helicopter">
            <Fade>
              <img src={helicopter} alt="" />
            </Fade>
          </div>
        </div>

        <div className="all_solutions">
          <Fade distance={"20%"} top duration={1300}>
            <h3 className="h3">All solutions</h3>
          </Fade>
          <h6 className="h6">
            Are there any concerns about using a tool like Chief of Staff AI?
          </h6>
          <ul id="all_solutions" ref={allSolutions}>
            {allSolutionsList.map((item, index) => {
              return (
                <li
                  className={solutionsShadow ? "shadow" : ""}
                  key={item.id}
                  onClick={() => navigate("/solutionsDetail?id=" + index)}
                >
                  <div className="content">
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                  <div className="img">
                    <img src={item.img} alt="" />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* Building enterprise-ready solutions */}

        <div className="enterprise-ready">
          <div className="img">
            <img src={bannerImg} alt="" />
          </div>
          <div className="enterprise_content">
            <Fade distance={"20%"} top duration={1300}>
              <h3>Chief of Staff AI Solutions</h3>
            </Fade>
            <p>
              Through artificial intelligence technology and data analysis,
              provide valuable information and insights to the company's
              leadership team to help them make more informed decisions.
            </p>
            <div className="botton" style={{ background: "#36ccb1" }}>
              Contact us
            </div>
          </div>
        </div>
        {/* Solution Features */}

        {/* Recent wonderful cases */}
        <div className="partners_with">
          <Fade distance={"20%"} top duration={1300}>
            <h3 className="h3">The partners with us</h3>
          </Fade>

          <h6 className="h6">
            Provide comprehensive data protection for
            <span> more than 10 million customers</span>
          </h6>

          <ul className="img_list">
            {wonderfulCasesImg.map((item, index) => {
              return (
                <li key={index}>
                  <img src={item} alt="" />
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <Footer />
    </>
  );
}
